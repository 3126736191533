// OTPModal.js

import React, { useState } from "react";
import { Modal, Box, Typography, IconButton, Button } from "@mui/material";
import closeIcon from "../../../../Assets/Images/cancel-circle-half-dot.png"; // Assuming you have a close icon image
import OtpInput from "react-otp-input";

const OTPModal = ({ isOpen, onClose, onSubmit }) => {
  const [otp, setOtp] = useState("");

  // const handleOtpChange = (event) => {
  //   setOtp(event.target.value);
  // };

  const handleFormSubmit = () => {
    onSubmit(otp);
    setOtp(""); // Clear OTP input after submission
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: 550,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 4,
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
          onClick={onClose} // Fix: Use onClose instead of handleClose
        >
          <img src={closeIcon} alt="close" />{" "}
          {/* Fix: Use closeIcon variable */}
        </IconButton>
        <Box>
          <Box
            sx={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
              py: 1,
            }}
          ></Box>
          <Typography
            id="logout-modal-description"
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: 32,
              textAlign: "center",
              py: 2,
              pb: 1,
            }}
            gutterBottom
          >
            OTP Verification
          </Typography>
          <Typography
            id="logout-modal-description"
            variant="p"
            sx={{ fontSize: 16, textAlign: "center", display: "block" }}
            gutterBottom
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
          <OtpInput
            inputStyle={{
              border: "2px solid #0B3013",
              borderRadius: "8px",
              width: "54px",
              height: "54px",
              fontSize: "18px",
              color: "#000",
              fontWeight: "400",
              caretColor: "blue",
            }}
            focusStyle={{
              border: "1px solid #CFD3DB",
              outline: "none",
            }}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputType="text"
            renderSeparator={
              <span style={{ paddingLeft: 5, paddingRight: 5 }}></span>
            }
            renderInput={(props) => <input {...props} className="otpInput" />}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
          <Button
            sx={{
              backgroundColor: "#0B3013!important",
              fontSize: 16,
              borderRadius: 20,
              textTransform: "capitalize",
              px: 6,
              py: 1,
            }}
            variant="contained"
            onClick={handleFormSubmit} // Submit OTP
          >
            Verify
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0B3013!important",
              fontSize: 16,
              borderRadius: 20,
              textTransform: "capitalize",
              px: 4,
              py: 1,
            }}
            onClick={onClose} // Close modal on cancel button click
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OTPModal;
